<template>
  <review-card title="Fenômenos Tumorais">
    <v-list-item
      v-for="(item, index) in tumoralPhenomena"
      :key="index"
      class="py-2 item-par"
    >
      <v-list-item-content>
        <v-list-item-title class="mb-3">
          {{ item.tumoralPhenomenon.order }} -
          {{ item.tumoralPhenomenon.name }}
        </v-list-item-title>
        <property-value
          property="Zona de resolução"
          :value="item.diagnoseResolutionZone.resolutionZone.name"
        />
        <property-value
          property="Ponto afetado"
          :value="item.diagnoseResolutionZone.affectedSpotParTrauma"
        />
        <pares-simple-list :pares="item.relatedPares" class="mb-3" />
        <property-value
          property="Comentários aos terapeutas"
          :value="item.therapistComments"
        />
        <property-value
          property="Comentários ao paciente"
          :value="item.patientComments"
        />
      </v-list-item-content>
    </v-list-item>
  </review-card>
</template>

<script>
export default {
  name: "TherapyReviewTumoralPhenomena",
  components: {
    ParesSimpleList: () =>
      import(
        "@/domains/therapy/shared/presentation/components/ParesSimpleList"
      ),
  },
  props: {
    tumoralPhenomena: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
